import React from 'react'
import { link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TextGroup from '../components/TextGroup';

const AccuCam = () => (
    <Layout>
     <SEO title="AccuCam" keywords={[`Nesting`, `Production Environment`, `react`]} />
     <div className="BlueGradient">
      <div className="--headerClear"></div>
      <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation  ">
        <h2><span>AccuCam</span> Nesting software made dynamic</h2>
        <p>AccuCAM gives you the fastest, most efficient way to get from paperwork to cut parts on your lasers.</p>
      </section>
    </div>
    <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>Product Overview</h5>
        <p className="DarkP">The easy-to-use system is powered by an industry leading nesting engine that guarantees improved material utilization and optimized laser processing time. From ERP to cut parts, AccuCAM gives you control over your laser processing.  Efficient nested sheets optimized for your lasers are automatically generated along with comprehensive shop floor documents that help guarantee parts are processed quickly and efficiently. Leave nothing to chance with AccuCAM.</p>
      </article>
    </section>
    <div className="--sectionPace">
    <section className="--centerMargin --maxwidthL --edgePadding">
      <artcle>
        <h2 className="DarkH">AccuCAM is designed for maximum productivity</h2>
        <p className="DarkP">Take nesting to a new level with a long list of features designed to do more in less time. </p>
      </artcle>
    </section>
    <section className="--centerMargin --maxwidthL --edgePadding --grid5050">
    <div className="no_bullet">
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Multi-mode True Shape Nesting</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Comprehensive Cutting Conditions</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Intelligent Micro-Tabbing</p>

            </div>   
      <div className="no_bullet">
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Automatic Part-in-Part Nesting</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Smart Grain Control</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Part Prioritization</p>
            </div>    
        </section>
        </div>
        <section className="--centerMargin --maxwidthL --grid6040 --edgePadding --sectionPace --alignCenter">
      <article>
        <h2 className="DarkH">Automation Options</h2>
        <p className="DarkP">Connect with AccuFAB for seamless ERP integration, Part production management, Remote access, and more.</p>
      </article>
      <div id="accuCamImg" className="--backgroundContain"></div>
    </section>
    <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">
        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
    </Layout>    

)

export default AccuCam 